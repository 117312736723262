import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Requesting information</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Best practices</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variants</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Launching a bot</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Error notifications</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`The following diagram outlines the primary components within the chatbot pattern.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "62.05357142857143%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Chatbot anatomy",
        "title": "Chatbot anatomy",
        "src": "/static/44b49cfb7f4e1f6f73e570218ce34794/fb070/chatbot-4.png",
        "srcSet": ["/static/44b49cfb7f4e1f6f73e570218ce34794/d6747/chatbot-4.png 288w", "/static/44b49cfb7f4e1f6f73e570218ce34794/09548/chatbot-4.png 576w", "/static/44b49cfb7f4e1f6f73e570218ce34794/fb070/chatbot-4.png 1152w", "/static/44b49cfb7f4e1f6f73e570218ce34794/fb104/chatbot-4.png 1728w", "/static/44b49cfb7f4e1f6f73e570218ce34794/8fefe/chatbot-4.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "chatbot-elements"
    }}>{`Chatbot elements`}</h3>
    <h4 {...{
      "id": "chatbot-header"
    }}>{`Chatbot header`}</h4>
    <p>{`The chatbot header contains the title of the chat and up to four action buttons that control the chatbot application. Typically, the “close” icon must always be included.`}</p>
    <h4 {...{
      "id": "system-message"
    }}>{`System message`}</h4>
    <p>{`System Messages represent what the bot has responded to the user with. Past messages will have a faded treatment, whereas active messages have an indicator applied (see below)`}</p>
    <h4 {...{
      "id": "structured-response"
    }}>{`Structured response`}</h4>
    <p>{`Structured Responses present choices to the user that are easy for the bot to understand. When selected, a structured response will change its visual appearance and a user message will appear with the same content.`}</p>
    <h4 {...{
      "id": "user-message"
    }}>{`User message`}</h4>
    <p>{`A user message indicates what the user has input, via voice, text input, or structured response.`}</p>
    <h4 {...{
      "id": "chatbot-input"
    }}>{`Chatbot input`}</h4>
    <p>{`The Chatbot Input is comprised of a text input field, a button for voice input (optional) and an submit button`}</p>
    <h2 {...{
      "id": "requesting-information"
    }}>{`Requesting information`}</h2>
    <p>{`The following flow shows a typical exchange between a user and a bot. This model can be used as a baseline to start building useful conversational experiences.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Requesting information from a chatbot",
        "title": "Requesting information from a chatbot",
        "src": "/static/77eba140eb800498f5940ebed875f3ca/fb070/chatbot-1.png",
        "srcSet": ["/static/77eba140eb800498f5940ebed875f3ca/d6747/chatbot-1.png 288w", "/static/77eba140eb800498f5940ebed875f3ca/09548/chatbot-1.png 576w", "/static/77eba140eb800498f5940ebed875f3ca/fb070/chatbot-1.png 1152w", "/static/77eba140eb800498f5940ebed875f3ca/fb104/chatbot-1.png 1728w", "/static/77eba140eb800498f5940ebed875f3ca/8fefe/chatbot-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h2>
    <p>{`At a minimum, use these best practices to help ensure a successful conversational experience. For more in-depth conversational principles, see the `}<a parentName="p" {...{
        "href": "content#conversation-design-principles-for-bots"
      }}>{`Conversational Design Principles`}</a></p>
    <h4 {...{
      "id": "introduction"
    }}>{`Introduction`}</h4>
    <p>{`Ensure your users know they are talking to a bot and that the bot has stated its purpose. Set the context for what questions users can ask about.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Chatbot greeting",
        "title": "Chatbot greeting",
        "src": "/static/455bc56ee7a5ec73069932d0c634e1a7/fb070/chatbot-7.png",
        "srcSet": ["/static/455bc56ee7a5ec73069932d0c634e1a7/d6747/chatbot-7.png 288w", "/static/455bc56ee7a5ec73069932d0c634e1a7/09548/chatbot-7.png 576w", "/static/455bc56ee7a5ec73069932d0c634e1a7/fb070/chatbot-7.png 1152w", "/static/455bc56ee7a5ec73069932d0c634e1a7/fb104/chatbot-7.png 1728w", "/static/455bc56ee7a5ec73069932d0c634e1a7/8fefe/chatbot-7.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "user-intent"
    }}>{`User intent`}</h4>
    <p>{`Typically, a user inputs an intent, which can be request for information or a task for the bot to complete. This can take the form of a text (shown here) as well as a structured response (see next section)`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "User Intent",
        "title": "User Intent",
        "src": "/static/83876ed2b9b2c43daf3ef401b8bd2c44/fb070/chatbot-8.png",
        "srcSet": ["/static/83876ed2b9b2c43daf3ef401b8bd2c44/d6747/chatbot-8.png 288w", "/static/83876ed2b9b2c43daf3ef401b8bd2c44/09548/chatbot-8.png 576w", "/static/83876ed2b9b2c43daf3ef401b8bd2c44/fb070/chatbot-8.png 1152w", "/static/83876ed2b9b2c43daf3ef401b8bd2c44/fb104/chatbot-8.png 1728w", "/static/83876ed2b9b2c43daf3ef401b8bd2c44/8fefe/chatbot-8.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "structured-responses"
    }}>{`Structured responses`}</h4>
    <p>{`Prompt for additional details with a menu of structured responses. This narrows the scope to more specific information to answer the user’s question.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structured Responses",
        "title": "Structured Responses",
        "src": "/static/02271847f904751afea226fe9d387935/fb070/chatbot-9.png",
        "srcSet": ["/static/02271847f904751afea226fe9d387935/d6747/chatbot-9.png 288w", "/static/02271847f904751afea226fe9d387935/09548/chatbot-9.png 576w", "/static/02271847f904751afea226fe9d387935/fb070/chatbot-9.png 1152w", "/static/02271847f904751afea226fe9d387935/fb104/chatbot-9.png 1728w", "/static/02271847f904751afea226fe9d387935/8fefe/chatbot-9.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "reflection"
    }}>{`Reflection`}</h4>
    <p>{`The bot should reflect its understanding of the query in the response (when appropriate) to ensure understanding, or before performing a significant action. Note: This process of slotfilling may need to be repeated until the bot has all the information required to answer the user’s initial question.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Reflection",
        "title": "Reflection",
        "src": "/static/018cdacf78beb514754f14ff102f7cd2/fb070/chatbot-10.png",
        "srcSet": ["/static/018cdacf78beb514754f14ff102f7cd2/d6747/chatbot-10.png 288w", "/static/018cdacf78beb514754f14ff102f7cd2/09548/chatbot-10.png 576w", "/static/018cdacf78beb514754f14ff102f7cd2/fb070/chatbot-10.png 1152w", "/static/018cdacf78beb514754f14ff102f7cd2/fb104/chatbot-10.png 1728w", "/static/018cdacf78beb514754f14ff102f7cd2/8fefe/chatbot-10.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "provide-a-response-and-request-feedback"
    }}>{`Provide a response and request feedback`}</h4>
    <p>{`The bot should provide a thoughtful, informative response to the user’s intent, based on the information they have provided throughout the conversation. When appropriate, be sure to provide an opportunity for users to give feedback. Consider allowing the user to input a custom response. Provide an opportunity for users to give feedback where possible. Consider allowing the user to input a custom response.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Provide a response and request feedback",
        "title": "Provide a response and request feedback",
        "src": "/static/018cdacf78beb514754f14ff102f7cd2/fb070/chatbot-11.png",
        "srcSet": ["/static/018cdacf78beb514754f14ff102f7cd2/d6747/chatbot-11.png 288w", "/static/018cdacf78beb514754f14ff102f7cd2/09548/chatbot-11.png 576w", "/static/018cdacf78beb514754f14ff102f7cd2/fb070/chatbot-11.png 1152w", "/static/018cdacf78beb514754f14ff102f7cd2/fb104/chatbot-11.png 1728w", "/static/018cdacf78beb514754f14ff102f7cd2/8fefe/chatbot-11.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`The chatbot window comes in two variants, regular and tight. Use the regular chatbot window variant when your chat interaction is the main experience and there are few to no other widgets on the screen. Consider pinning the chat window to one side of the screen if more space is required. Use the tight variant and related symbols when embedding a chat interaction into a larger experience with significant amounts of existing content/information.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Chatbot window comes in two sizes, regular and tight",
        "title": "Chatbot window comes in two sizes, regular and tight",
        "src": "/static/b68a505dab89b4d2084f839667dfe59e/fb070/chatbot-2.png",
        "srcSet": ["/static/b68a505dab89b4d2084f839667dfe59e/d6747/chatbot-2.png 288w", "/static/b68a505dab89b4d2084f839667dfe59e/09548/chatbot-2.png 576w", "/static/b68a505dab89b4d2084f839667dfe59e/fb070/chatbot-2.png 1152w", "/static/b68a505dab89b4d2084f839667dfe59e/fb104/chatbot-2.png 1728w", "/static/b68a505dab89b4d2084f839667dfe59e/902fb/chatbot-2.png 2304w", "/static/b68a505dab89b4d2084f839667dfe59e/3ff35/chatbot-2.png 2400w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "launching-a-bot-experimental"
    }}>{`Launching a bot (Experimental)`}</h2>
    <p>{`Bots should be launched from a clear floating trigger at the bottom right of the screen, or from a button embedded within the UI. See Carbon Chatbot Add-on Design Kit for the appropriate variants.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Launching Chatbot Windows",
        "title": "Launching Chatbot Windows",
        "src": "/static/fd192bcda2285224d2643061ec61462a/fb070/chatbot-5.png",
        "srcSet": ["/static/fd192bcda2285224d2643061ec61462a/d6747/chatbot-5.png 288w", "/static/fd192bcda2285224d2643061ec61462a/09548/chatbot-5.png 576w", "/static/fd192bcda2285224d2643061ec61462a/fb070/chatbot-5.png 1152w", "/static/fd192bcda2285224d2643061ec61462a/fb104/chatbot-5.png 1728w", "/static/fd192bcda2285224d2643061ec61462a/902fb/chatbot-5.png 2304w", "/static/fd192bcda2285224d2643061ec61462a/3ff35/chatbot-5.png 2400w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "error-notifications-experimental"
    }}>{`Error notifications (Experimental)`}</h2>
    <p>{`Be sure to indicate to a user when their message has not been sent due to a connectivity or server problem. When in a connected state, temporarily show the success notification, and then remove it along with any message error indicators.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.57142857142857%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Chatbot Error Notification",
        "title": "Chatbot Error Notification",
        "src": "/static/e3c1c26de28fc6ec7c0b1adf669e2849/fb070/chatbot-6.png",
        "srcSet": ["/static/e3c1c26de28fc6ec7c0b1adf669e2849/d6747/chatbot-6.png 288w", "/static/e3c1c26de28fc6ec7c0b1adf669e2849/09548/chatbot-6.png 576w", "/static/e3c1c26de28fc6ec7c0b1adf669e2849/fb070/chatbot-6.png 1152w", "/static/e3c1c26de28fc6ec7c0b1adf669e2849/fb104/chatbot-6.png 1728w", "/static/e3c1c26de28fc6ec7c0b1adf669e2849/8fefe/chatbot-6.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      